/* eslint-disable import/no-anonymous-default-export */
import {
  CatatanTransaksiHarianDetail,
  CatatanTransaksiHarianList,
  CloseProduksiCreate,
  CloseProduksiDetail,
  // Close Produksi
  CloseProduksiList,
  CRUDPabrik,
  DetailAnalisaBarangJadi,
  DetailItemBarang,
  DetailJobMix,
  DetailJobMixDesign,
  // Jenis Barang Jadi
  JenisBarangJadi,
  LaporanDetailProduksi,
  LaporanListProduksi,
  ListItemBarang,
  // Job Mix
  ListJobMix,
  ListJobMixDesign,
  // Pabrik
  ListPabrik,
  // Realisasi Produksi
  ListRealisasiJobMix,
  ListSalesOrderJobMix,
  // Laporan Progress Produksi
  LogProgressProduksi,
  PermintaanProduksiAsetCreate,
  PermintaanProduksiAsetList,
  PermintaanProduksiAsetPermintaanAsetList,
  PermintaanProduksiCreate,
  // Permintaan Produksi
  PermintaanProduksiList,
  PermintaanProduksiProduksiList,
  ProduksiAsetCreate,
  ProduksiAsetDetail,
  ProduksiAsetList,
  ProduksiAsetPermintaanAsetList,
  ProduksiAsetRealisasiProduksiAsetList,
  ProduksiList,
  ProgressProduksi,
  // Realisasi Produksi
  RealiasiProduksiList,
  RealisasiJobMix,
  RealisasiListJobMix,
  RealisasiProduksiAsetCreate,
  RealisasiProduksiAsetDetail,
  RealisasiProduksiAsetList,
  RealisasiProduksiCreate,
  RealisasiProduksiDetail,
  RealisasiProduksiListProduksi,
  // Analisa Barang Jadi
  RegistrasiAnalisaBarangJadi,
  // Job Mix Design
  RegistrasiJobMixDesign,
  TambahAnalisaBarangJadi,
  TambahJobMix,
  TambahJobMixDesign,
  UbahAnalisaBarangJadi,
  UbahJobMix,
  UbahJobMixDesign,
} from "pages";
import {
  CloseProduksiAsetCreate,
  CloseProduksiAsetDetail,
  CloseProduksiAsetList,
  CloseProduksiAsetProduksiAsetList,
} from "pages/Transaksi";

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // MASTER
  // Registrasi Barang Jadi
  // {
  //   exact: true,
  //   path: "/master/registrasi-barang-jadi",
  //   page: RegistrasiBarangJadi,
  //   hak: ["SUPA", "PRD_MAS_BRJ"],
  // },
  // {
  //   exact: true,
  //   path: "/master/registrasi-barang-jadi/tambah",
  //   page: TambahBarangJadi,
  //   hak: ["SUPA", "PRD_MAS_BRJ"],
  // },
  // {
  //   exact: true,
  //   path: "/master/registrasi-barang-jadi/ubah/:id_item_atribut",
  //   page: UbahBarangJadi,
  //   hak: ["SUPA", "PRD_MAS_BRJ"],
  // },
  // {
  //   exact: true,
  //   path: "/master/registrasi-barang-jadi/detail/:id_item_atribut",
  //   page: DetailBarangJadi,
  //   hak: ["SUPA", "PRD_MAS_BRJ"],
  // },

  // Pabrik
  {
    exact: true,
    path: "/master/pabrik",
    page: ListPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },
  {
    exact: true,
    path: "/master/pabrik/:tipe",
    page: CRUDPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },
  {
    exact: true,
    path: "/master/pabrik/:tipe/:id_pabrik",
    page: CRUDPabrik,
    hak: ["SUPA", "PRD_MAS_PAB"],
  },

  // JENIS BARANG JADI
  {
    exact: true,
    path: "/master/jenis-barang-jadi",
    page: JenisBarangJadi,
    hak: ["SUPA", "PRD_MAS_JBJ"],
  },

  // Job Mix Design
  {
    exact: true,
    path: "/master/job-mix-design",
    page: RegistrasiJobMixDesign,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/list-item-barang",
    page: ListItemBarang,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/list-item-barang/detail/:id",
    page: DetailItemBarang,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/tambah",
    page: TambahJobMixDesign,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/tambah/:id",
    page: TambahJobMixDesign,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/ubah/:id_jobmix_design",
    page: UbahJobMixDesign,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },
  {
    exact: true,
    path: "/master/job-mix-design/detail/:id_jobmix_design",
    page: DetailJobMixDesign,
    hak: ["SUPA", "PRD_MAS_JMD"],
  },

  // Analisa Barang Jadi
  {
    exact: true,
    path: "/master/analisa-barang-jadi",
    page: RegistrasiAnalisaBarangJadi,
    hak: ["SUPA", "PRD_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/list-job-mix-design",
    page: ListJobMixDesign,
    hak: ["SUPA", "PRD_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/tambah/:id_jobmix_design",
    page: TambahAnalisaBarangJadi,
    hak: ["SUPA", "PRD_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/ubah/:id_analisa_barang_jadi",
    page: UbahAnalisaBarangJadi,
    hak: ["SUPA", "PRD_MAS_ABJ"],
  },
  {
    exact: true,
    path: "/master/analisa-barang-jadi/detail/:id_analisa_barang_jadi",
    page: DetailAnalisaBarangJadi,
    hak: ["SUPA", "PRD_MAS_ABJ"],
  },

  // TRANSAKSI
  // Produksi
  {
    exact: true,
    path: "/transaksi/job-mix",
    page: ListJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/sales-order",
    page: ListSalesOrderJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/detail/:id",
    page: DetailJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/tambah",
    page: TambahJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/tambah/:id",
    page: TambahJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },
  {
    exact: true,
    path: "/transaksi/job-mix/ubah/:id",
    page: UbahJobMix,
    hak: ["SUPA", "PRD_TRN_JBX"],
  },

  // Realisasi Produksi Old
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix",
    page: ListRealisasiJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix/:tipe/:id_jobmix",
    page: RealisasiJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-job-mix/list-job-mix",
    page: RealisasiListJobMix,
    hak: ["SUPA", "PRD_TRN_REP"],
  },

  // Realisasi Produksi
  {
    exact: true,
    path: "/transaksi/realisasi-produksi",
    page: RealiasiProduksiList,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi/list-produksi",
    page: RealisasiProduksiListProduksi,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi/detail/:id",
    page: RealisasiProduksiDetail,
    hak: ["SUPA", "PRD_TRN_REP"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi/tambah/:id",
    page: RealisasiProduksiCreate,
    hak: ["SUPA", "PRD_TRN_REP"],
  },

  // CLOSE JOB MIX
  {
    exact: true,
    path: "/transaksi/close-job-mix",
    page: CloseProduksiList,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
  {
    exact: true,
    path: "/transaksi/close-job-mix/produksi",
    page: ProduksiList,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
  {
    exact: true,
    path: "/transaksi/close-job-mix/produksi/tambah/:id",
    page: CloseProduksiCreate,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },
  {
    exact: true,
    path: "/transaksi/close-job-mix/detail/:id",
    page: CloseProduksiDetail,
    hak: ["SUPA", "PRD_TRN_CLJ"],
  },

  // Permintaan Produksi
  {
    exact: true,
    path: "/transaksi/permintaan-produksi",
    page: PermintaanProduksiList,
    hak: ["SUPA", "PRD_TRN_PPR"],
  },
  {
    exact: true,
    path: "/transaksi/permintaan-produksi/produksi",
    page: PermintaanProduksiProduksiList,
    hak: ["SUPA", "PRD_TRN_PPR"],
  },
  {
    exact: true,
    path: "/transaksi/permintaan-produksi/tambah/:id",
    page: PermintaanProduksiCreate,
    hak: ["SUPA", "PRD_TRN_PPR"],
  },

  // Produksi Aset
  {
    exact: true,
    path: "/transaksi/produksi-aset",
    page: ProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_PAT"],
  },
  {
    exact: true,
    path: "/transaksi/produksi-aset/permintaan-aset",
    page: ProduksiAsetPermintaanAsetList,
    hak: ["SUPA", "PRD_TRN_PAT"],
  },
  {
    exact: true,
    path: "/transaksi/produksi-aset/tambah/:id",
    page: ProduksiAsetCreate,
    hak: ["SUPA", "PRD_TRN_PAT"],
  },
  {
    exact: true,
    path: "/transaksi/produksi-aset/detail/:id",
    page: ProduksiAsetDetail,
    hak: ["SUPA", "PRD_TRN_PAT"],
  },

  // Permintaan Produksi Aset
  {
    exact: true,
    path: "/transaksi/permintaan-produksi-aset",
    page: PermintaanProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_PPAT"],
  },
  {
    exact: true,
    path: "/transaksi/permintaan-produksi-aset/produksi-aset",
    page: PermintaanProduksiAsetPermintaanAsetList,
    hak: ["SUPA", "PRD_TRN_PPAT"],
  },
  {
    exact: true,
    path: "/transaksi/permintaan-produksi-aset/tambah/:id",
    page: PermintaanProduksiAsetCreate,
    hak: ["SUPA", "PRD_TRN_PPAT"],
  },
  {
    exact: true,
    path: "/transaksi/produksi-aset/detail/:id",
    page: ProduksiAsetDetail,
    hak: ["SUPA", "PRD_TRN_PPAT"],
  },

  // Realisasi Produksi Aset
  {
    exact: true,
    path: "/transaksi/realisasi-produksi-aset",
    page: RealisasiProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_RPA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi-aset/produksi-aset",
    page: ProduksiAsetRealisasiProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_RPA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi-aset/tambah/:id",
    page: RealisasiProduksiAsetCreate,
    hak: ["SUPA", "PRD_TRN_RPA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-produksi-aset/detail/:id",
    page: RealisasiProduksiAsetDetail,
    hak: ["SUPA", "PRD_TRN_RPA"],
  },

  // Close Produksi Aset
  {
    exact: true,
    path: "/transaksi/close-produksi-aset",
    page: CloseProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_CPA"],
  },
  {
    exact: true,
    path: "/transaksi/close-produksi-aset/produksi-aset",
    page: CloseProduksiAsetProduksiAsetList,
    hak: ["SUPA", "PRD_TRN_CPA"],
  },
  {
    exact: true,
    path: "/transaksi/close-produksi-aset/tambah/:id",
    page: CloseProduksiAsetCreate,
    hak: ["SUPA", "PRD_TRN_CPA"],
  },
  {
    exact: true,
    path: "/transaksi/close-produksi-aset/detail/:id",
    page: CloseProduksiAsetDetail,
    hak: ["SUPA", "PRD_TRN_CPA"],
  },

  // Laporan Produksi
  {
    exact: true,
    path: "/laporan/laporan-produksi",
    page: LaporanListProduksi,
    hak: ["SUPA", "PRD_RPT_JBX"],
  },
  {
    exact: true,
    path: "/laporan/laporan-produksi/detail/:id",
    page: LaporanDetailProduksi,
    hak: ["SUPA", "PRD_RPT_JBX"],
  },

  // Laporan Progres Produksi
  {
    exact: true,
    path: "/laporan/progress-produksi",
    page: ProgressProduksi,
    hak: ["SUPA", "PRD_RPT_PRP"],
  },
  {
    exact: true,
    path: "/laporan/progress-produksi/log",
    page: LogProgressProduksi,
    hak: ["SUPA", "PRD_RPT_PRP"],
  },

  // Laporan Catatan Transaksi Harian Produksi
  {
    exact: true,
    path: "/laporan/catatan-transaksi-harian",
    page: CatatanTransaksiHarianList,
    hak: ["SUPA", "PRD_RPT_CTHP"],
  },
  {
    exact: true,
    path: "/laporan/catatan-transaksi-harian/detail/:id",
    page: CatatanTransaksiHarianDetail,
    hak: ["SUPA", "PRD_RPT_CTHP"],
  },
];
