/* eslint-disable react-hooks/exhaustive-deps */
import { RealisasiProduksiApi } from "api";
import Axios from "axios";
import { BackButton, DataStatus } from "components";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { InfoSection, TabItem, TabWaste } from "./Comps";

export const RealisasiProduksiDetail = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [tab, setTab] = useState("bahan");
  const [dataDetail, setDataDetail] = useState([]);
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataAlat, setDataAlat] = useState([]);
  const [dataSubkon, setDataSubkon] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataWaste, setDataWaste] = useState([]);
  const [fetching, setFetching] = useState({
    loading: true,
    error: false,
  });

  const mapDataItem = (dataItem = []) =>
    dataItem
      ? dataItem.map((val) => ({
          id:
            val?.id_transfer_produksi_bahan ??
            val.id_transfer_produksi_upah ??
            val.id_transfer_produksi_subkon ??
            val.id_transfer_produksi_overhead,
          tgl_transfer:
            val?.tgl_transfer_produksi_bahan ??
            val.tgl_transfer_produksi_upah ??
            val.tgl_transfer_produksi_alat_mesin ??
            val.tgl_transfer_produksi_subkon ??
            val.tgl_transfer_produksi_overhead,
          no_transfer:
            val?.no_transfer_produksi_bahan ??
            val.no_transfer_produksi_upah ??
            val.no_transfer_produksi_alat_mesin ??
            val.no_transfer_produksi_subkon ??
            val.no_transfer_produksi_overhead,
          nama_item: val?.nama_item_buaso ?? val?.nama_item,
          nama_gudang_asal: val?.nama_gudang_asal ?? "",
          nama_gudang_tujuan: val?.nama_gudang_tujuan ?? "",
          nama_vendor: val?.nama_vendor,
          satuan: val?.nama_satuan,
          qty_transfer: val?.qty_transfer,
          qty_realisasi:
            val?.qty_realisasi_bahan ??
            val.qty_realisasi_upah ??
            val.qty_realisasi_alat_mesin ??
            val.qty_realisasi_subkon ??
            val.qty_realisasi_overhead,
          qty_terealisasi: val?.sum_realisasi ?? 0,
        }))
      : [];

  const getInitialData = () =>
    Axios.all([RealisasiProduksiApi.getSingle({ id_realisasi_produksi: id })])
      .then(
        Axios.spread((data) => {
          const getDataDetail = data?.data?.data ?? {};

          setDataDetail(getDataDetail);
          setDataBahan(mapDataItem(getDataDetail?.bahan));
          setDataUpah(mapDataItem(getDataDetail?.upah));
          setDataAlat(mapDataItem(getDataDetail?.alat_mesin));
          setDataSubkon(mapDataItem(getDataDetail?.subkon));
          setDataOverhead(mapDataItem(getDataDetail?.overhead));
          setDataWaste(getDataDetail?.waste);
          setFetching({
            loading: false,
            error: false,
          });
        })
      )
      .catch(() => {
        setFetching({
          loading: false,
          error: true,
        });
      });

  useEffect(() => {
    setNavbarTitle("Realisasi Produksi");
    getInitialData();
  }, []);

  if (fetching.loading || fetching.error) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-end">
          <b className="px-2">Informasi Realisasi Produksi</b>
          <BackButton size="sm" onClick={history.goBack} />
        </div>
        <DataStatus
          loading={fetching.loading}
          text={fetching.loading ? "Memuat data . . ." : "Data gagal dimuat"}
        />
      </>
    );
  }

  return (
    <Formik>
      {() => (
        <>
          {/* Title */}
          <div className="d-flex justify-content-between align-items-end">
            <b className="px-2">Informasi Realisasi Produksi</b>
            <BackButton size="sm" onClick={history.goBack} />
          </div>

          {/* Info Section */}
          <div className="mt-2 p-3 bg-white rounded border">
            <InfoSection action="DETAIL" data={dataDetail} />
          </div>

          {/* Tabs Section */}
          <div className="mt-4 mb-1">
            <b className="px-2">Data Realisasi Produksi</b>
          </div>
          <Tabs
            activeKey={tab}
            onSelect={(key) => setTab(key)}
            transition={false}
          >
            <Tab
              eventKey="bahan"
              title="Realisasi Bahan"
              children={
                <TabItem
                  action="DETAIL"
                  type="Bahan"
                  dataRealisasi={dataBahan}
                  setDataRealisasi={setDataBahan}
                />
              }
            />
            <Tab
              eventKey="upah"
              title="Realisasi Upah"
              children={
                <TabItem
                  action="DETAIL"
                  type="Upah"
                  dataRealisasi={dataUpah}
                  setDataRealisasi={setDataUpah}
                />
              }
            />
            <Tab
              eventKey="alat"
              title="Realisasi Alat & Mesin"
              children={
                <TabItem
                  action="DETAIL"
                  type="Alat & Mesin"
                  dataRealisasi={dataAlat}
                  setDataRealisasi={setDataAlat}
                />
              }
            />
            <Tab
              eventKey="subkon"
              title="Realisasi Subkon"
              children={
                <TabItem
                  action="DETAIL"
                  type="Subkon"
                  dataRealisasi={dataSubkon}
                  setDataRealisasi={setDataSubkon}
                />
              }
            />
            <Tab
              eventKey="overhead"
              title="Realisasi Overhead"
              children={
                <TabItem
                  action="DETAIL"
                  type="Overhead"
                  dataRealisasi={dataOverhead}
                  setDataRealisasi={setDataOverhead}
                />
              }
            />
            <Tab
              eventKey="waste"
              title="Realisasi Waste"
              children={
                <TabWaste
                  action="DETAIL"
                  dataWaste={dataWaste}
                  setDataWaste={setDataWaste}
                />
              }
            />
          </Tabs>
        </>
      )}
    </Formik>
  );
};
